import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";

function AddWashBoy() {
  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="Create Wash Boy" />
        <hr className="my-4" />

        {/* Form layout with left alignment */}
        <div className="container max-w-lg text-start">
          <div className="mb-5">
            <label htmlFor="wash-boy-name" className="block mb-2 text-sm font-medium text-gray-900">
              Wash Boy Name
            </label>
            <input
              type="text"
              id="wash-boy-name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Enter wash boy name"
              required
            />
          </div>

          <div className="mb-5">
            <label htmlFor="email-address" className="block mb-2 text-sm font-medium text-gray-900">
              Email Address
            </label>
            <input
              type="email"
              id="email-address"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Enter email address"
              required
            />
          </div>

          <div className="mb-5">
            <label htmlFor="phone-number" className="block mb-2 text-sm font-medium text-gray-900">
              Phone Number
            </label>
            <input
              type="tel"
              id="phone-number"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Enter phone number"
              required
            />
          </div>

          <div className="mb-5">
            <label htmlFor="set-password" className="block mb-2 text-sm font-medium text-gray-900">
              Set Password
            </label>
            <input
              type="password"
              id="set-password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Set account password"
              required
            />
          </div>

          <div className="mb-5">
            <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900">
              Confirm Password
            </label>
            <input
              type="password"
              id="confirm-password"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Confirm account password"
              required
            />
          </div>

          <div className="mb-5">
            <label htmlFor="status" className="block mb-2 text-sm font-medium text-gray-900">
              Status
            </label>
            <select
              id="status"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            >
              <option selected>Active / Inactive</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>

          <div className="flex justify-left mt-6">
            <button
              type="submit"
              className="py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-[#000] hover:bg-[#3385c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Create Wash Boy
            </button>
          </div>
        </div>
      </MainSection>
    </div>
  );
}

export default AddWashBoy;
