import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";

function AddVendor() {
  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="Create New Enquiry" />

        {/* Container for two-column layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="container text-start">
            <div className="my-5">
              <label
                htmlFor="date"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Enquiry Date
              </label>
              <input
                type="date"
                id="date"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-10"
                placeholder="Select enquiry date"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Customer Name
              </label>
              <input
                type="text"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter customer name"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="mobile"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Mobile Number
              </label>
              <input
                type="number"
                id="mobile"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter mobile number"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="address"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Address
              </label>
              <input
                type="text"
                id="address"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter address"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="latitude"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Latitude & Longitude
              </label>
              <input
                type="text"
                id="latitude"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter latitude & longitude"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="customer-type"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Customer Type
              </label>
              <select
                id="customer-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Select customer type</option>
                <option value="1">Select 1</option>
                <option value="2">Select 2</option>
                <option value="3">Select 3</option>
                <option value="4">Select 4</option>
              </select>
            </div>

            <div className="mb-5">
              <label
                htmlFor="reference-type"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Reference Type
              </label>
              <select
                id="reference-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Select reference type</option>
                <option value="1">Select 1</option>
                <option value="2">Select 2</option>
                <option value="3">Select 3</option>
                <option value="4">Select 4</option>
              </select>
            </div>
          </div>

          {/* Right Column - Add similar input fields here */}
          <div className="container text-start">
            <div className="my-5">
              <label
                htmlFor="vehicle-name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Vehicle Name
              </label>
              <select
                id="reference-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Enter vehicle name</option>
                <option value="1">Select 1</option>
                <option value="2">Select 2</option>
                <option value="3">Select 3</option>
                <option value="4">Select 4</option>
              </select>
            </div>

            <div className="mb-5">
              <label
                htmlFor="vehicle-model"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Vehicle Model
              </label>
              <input
                type="text"
                id="vehicle-model"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter vehicle model"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="vehicle-color"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Vehicle Color
              </label>
              <select
                id="reference-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Enter vehicle color</option>
                <option value="1">Select 1</option>
                <option value="2">Select 2</option>
                <option value="3">Select 3</option>
                <option value="4">Select 4</option>
              </select>
            </div>

            <div className="mb-5">
              <label
                htmlFor="vehicle-no"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Vehicle No
              </label>
              <input
                type="text"
                id="vehicle-no"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter vehicle number"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="service-type"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Service Type
              </label>
              <select
                id="service-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Select service type</option>
                <option value="1">Select 1</option>
                <option value="2">Select 2</option>
                <option value="3">Select 3</option>
                <option value="4">Select 4</option>
              </select>
            </div>

            <div className="mb-5">
              <label
                htmlFor="desired-date"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Desired Date
              </label>
              <input
                type="date"
                id="desired-date"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-10"
                placeholder="Select desired date"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="desired-time"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Desired Time
              </label>
              <input
                type="time"
                id="desired-time"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 pr-10"
                placeholder="Select desired time"
                required
              />
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-6">
          <button
            type="submit"
            className="w-full md:w-auto flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-[#000] hover:bg-[#3385c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Create New Enquiry
          </button>
        </div>
      </MainSection>
    </div>
  );
}

export default AddVendor;
