import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect } from "react";
import { Line } from "react-chartjs-2"; // Import Line chart from react-chartjs-2
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"; // Import necessary components from Chart.js
import MainSection from "../../components/mainLayout/Main";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import {
  AiOutlineLineChart,
  AiOutlineUser,
  AiOutlineShoppingCart,
} from "react-icons/ai"; // Import relevant icons
import { FaCar, FaUsers } from "react-icons/fa";
import DatatableSection from "../../components/datatableSection/DatatableSection";
import { AiOutlineEye } from "react-icons/ai"; // Import the eye icon from react-icons
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net"; // Import DataTables

import $ from "jquery"; // Import jQuery

// Register the necessary components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const data = [];
for (let i = 1; i <= 10; i++) {
  // Add sample data for demo purposes
  data.push({
    enquiryId: `COC${i}023`,
    date: "02/02/23",
    customerName: `Customer Name`,
    mobileNo: "9123456789",
    address: "Lorem ipsum dolor sit amet...",
    vehicleName: ["Renault Duster", "Ford Figo", "Fiat Punto", "Tata Harrier", "Jeep Compass", "Honda City"][i % 6],
    vehicleNo: ["KA 41 Z 5493", "KA 55 AM 4395", "KA 01 MJ 1456"][i % 3],
    customerType: ["New", "Existing"][i % 2],
    desiredTimeDate: "7:00 a.m., 02/02/24",
  });
}

// Mock Data for Chart
const chartData = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Revenue",
      data: [
        10000, 15000, 20000, 25000, 30000, 35000, 33000, 31000, 34000, 36000,
        37000, 40000,
      ],
      fill: false,
      borderColor: "rgb(255, 99, 132)",
      tension: 0.1,
    },
  ],
};

// Options for Chart
const chartOptions = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

const Dashboard = () => {
  // Sample data for the table
  const tableData = [
    {
      enquiryId: "COC10123",
      date: "02/02/23",
      customerName: "Customer Name",
      mobileNo: "9123456789",
      address: "Lorem ipsum dolor sit amet...",
      vehicleName: "Renault Duster",
      vehicleNo: "KA 41 Z 5493",
      customerType: "New",
      desiredTimeDate: "7:00 a.m. 02/02/24",
    },
    // Add more data as needed
  ];


  useEffect(() => {
    // Initialize DataTable after the component has mounted
    $("#vendorTable").DataTable({
      paging: true,
      searching: true,
      ordering: true,
      pageLength: 10,
      lengthMenu: [10, 20, 30, 50],
    });

    // Cleanup function to destroy the DataTable when the component unmounts
    return () => {
      $("#vendorTable").DataTable().destroy();
    };
  }, []);

  useEffect(() => {
    // Initialize DataTable after the component has mounted
    $("#vendorTable1").DataTable({
      paging: true,
      searching: true,
      ordering: true,
      pageLength: 10,
      lengthMenu: [10, 20, 30, 50],
    });

    // Cleanup function to destroy the DataTable when the component unmounts
    return () => {
      $("#vendorTable1").DataTable().destroy();
    };
  }, []);

  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <SectionHeader title="Dashboard" />

        {/* Summary Cards */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
          <div className="p-4 bg-white rounded shadow-sm border border-gray-200 flex items-center justify-between">
            <div>
              <span className="text-gray-600">Total Revenue</span>
              <span className="block text-black text-lg font-semibold">
                ₹1,55,799
              </span>
              <p className="text-green-500 text-sm mt-1">
                +20% from last month
              </p>
            </div>
            <div className="flex items-center justify-center w-10 h-10 bg-black rounded">
              <AiOutlineLineChart className="text-white text-xl" />{" "}
              {/* Icon for Revenue */}
            </div>
          </div>

          <div className="p-4 bg-white rounded shadow-sm border border-gray-200 flex items-center justify-between">
            <div>
              <span className="text-gray-600">Subscriptions</span>
              <span className="block text-black text-lg font-semibold">
                5,799
              </span>
              <p className="text-green-500 text-sm mt-1">+2% from last month</p>
            </div>
            <div className="flex items-center justify-center w-10 h-10 bg-black rounded">
              <FaUsers className="text-white text-xl" />{" "}
              {/* Icon for Subscriptions */}
            </div>
          </div>

          <div className="p-4 bg-white rounded shadow-sm border border-gray-200 flex items-center justify-between">
            <div>
              <span className="text-gray-600">Washes Today</span>
              <span className="block text-black text-lg font-semibold">99</span>
              <p className="text-gray-500 text-sm mt-1">+0% from last month</p>
            </div>
            <div className="flex items-center justify-center w-10 h-10 bg-black rounded">
              <FaCar className="text-white text-xl" /> {/* Icon for Washes Today */}
            </div>
          </div>

          <div className="p-4 bg-white rounded shadow-sm border border-gray-200 flex items-center justify-between">
            <div>
              <span className="text-gray-600">Total Users</span>
              <span className="block text-black text-lg font-semibold">
                5,799
              </span>
              <p className="text-green-500 text-sm mt-1">
                +20% from last month
              </p>
            </div>
            <div className="flex items-center justify-center w-10 h-10 bg-black rounded">
              <AiOutlineUser className="text-white text-xl" />{" "}
              {/* Icon for Total Users */}
            </div>
          </div>
        </div>

        {/* Revenue Chart */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <div className="col-span-2 p-4 bg-white rounded shadow-sm border border-gray-200">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-semibold text-black">Revenue</h2>
              <select className="border border-gray-300 rounded px-2 py-1 text-sm">
                <option value="this-month">This Month</option>
                <option value="last-month">Last Month</option>
              </select>
            </div>
            <Line data={chartData} options={chartOptions} />
          </div>

          {/* Top Packages */}
          <div className="p-4 bg-white rounded shadow-sm border border-gray-200">
            <h2 className="text-lg font-semibold text-black mb-4">
              Top Packages
            </h2>
            <ul className="space-y-2">
              <li className="flex justify-between">
                <span className="font-semibold">Package Name</span>
                <span className="font-semibold">60</span>
              </li>
              <li className="flex justify-between">
                <span className="font-semibold">Package Name</span>
                <span className="font-semibold">44</span>
              </li>
              <li className="flex justify-between">
                <span className="font-semibold">Package Name</span>
                <span className="font-semibold">42</span>
              </li>
              <li className="flex justify-between">
                <span className="font-semibold">Package Name</span>
                <span className="font-semibold">30</span>
              </li>
              <li className="flex justify-between">
                <span className="font-semibold">Package Name</span>
                <span className="font-semibold">27</span>
              </li>
              <li className="flex justify-between">
                <span className="font-semibold">Package Name</span>
                <span className="font-semibold">20</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Recent Enquiries Table */}

        <SectionHeader title="Recent Enquiries" />
<DatatableSection>
<div className="overflow-x-auto">
  <table id="vendorTable" className="min-w-full border-collapse w-full">
    <thead className="bg-gray-100">
      <tr>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Enquiry ID</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Date</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Customer Name</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Mobile No</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Address</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Vehicle Name</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Vehicle No</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Customer Type</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Desired Time & Date</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Action</th>
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index} className="hover:bg-gray-50">
          <td className="py-2 px-4 border-b">{item.enquiryId}</td>
          <td className="py-2 px-4 border-b">{item.date}</td>
          <td className="py-2 px-4 border-b">{item.customerName}</td>
          <td className="py-2 px-4 border-b">{item.mobileNo}</td>
          <td className="py-2 px-4 border-b">{item.address}</td>
          <td className="py-2 px-4 border-b">{item.vehicleName}</td>
          <td className="py-2 px-4 border-b">{item.vehicleNo}</td>
          <td className="py-2 px-4 border-b">
            <select className="border border-gray-300 rounded px-2 py-1">
              <option value="New" selected={item.customerType === "New"}>
                New
              </option>
              <option value="Existing" selected={item.customerType === "Existing"}>
                Existing
              </option>
            </select>
          </td>
          <td className="py-2 px-4 border-b">{item.desiredTimeDate}</td>
          <td className="py-2 px-4 border-b">
            <button className="bg-black text-white px-3 py-1 rounded flex items-center">
              View <AiOutlineEye className="ml-2" /> {/* Eye icon with margin on the left */}
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  </div>
</DatatableSection>

<SectionHeader title="Monthly Packages" />
<DatatableSection>
<div className="overflow-x-auto">
  <table id="vendorTable1" className="min-w-full border-collapse w-full">
    <thead className="bg-gray-100">
      <tr>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Purchase Date</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Expiry Date</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Payment Date</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Amount</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Premium Wash</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Quick Wash</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Name</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Mobile No</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Vehicle Name</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Vehicle No</th>
        <th className="py-3 px-4 border-b text-left text-sm font-medium">Action</th>
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr key={index} className="hover:bg-gray-50">
          <td className="py-2 px-4 border-b">{item.date}</td>
          <td className="py-2 px-4 border-b">{item.date}</td>
          <td className="py-2 px-4 border-b">{item.date}</td>
          <td className="py-2 px-4 border-b">{index % 2 === 0 ? 500 : 800}</td>
          <td className="py-2 px-4 border-b">{`0${index % 5}`}</td>
          <td className="py-2 px-4 border-b">{`0${index % 5}`}</td>
          <td className="py-2 px-4 border-b">{item.customerName}</td>
          <td className="py-2 px-4 border-b">{item.mobileNo}</td>
          <td className="py-2 px-4 border-b">{item.vehicleName}</td>
          <td className="py-2 px-4 border-b">{item.vehicleNo}</td>
          <td className="py-2 px-4 border-b">
            <button className="bg-black text-white px-3 py-1 rounded flex items-center">
              View <AiOutlineEye className="ml-2" />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  </div>
</DatatableSection>

      </MainSection>
    </>
  );
};

export default Dashboard;
