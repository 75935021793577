import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";

function AddPackageUser() {
  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="Create New" />
        <hr/>

        {/* Container for two-column layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="container text-start">
            <div className="mb-5">
              <label htmlFor="package-type" className="block mb-2 text-sm font-medium text-gray-900">
                Package Type
              </label>
              <select
                id="package-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Enter package type</option>
                <option value="1">Package 1</option>
                <option value="2">Package 2</option>
              </select>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
  {/* First Line - Two Fields */}
  <div className="mb-0">
    <label htmlFor="date-of-purchase" className="block mb-2 text-sm font-medium text-gray-900">
      Date Of Purchase
    </label>
    <input
      type="date"
      id="date-of-purchase"
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    />
  </div>

  <div className="mb-0">
    <label htmlFor="date-of-expiry" className="block mb-2 text-sm font-medium text-gray-900">
      Date Of Expiry
    </label>
    <input
      type="date"
      id="date-of-expiry"
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    />
  </div>

  {/* Second Line - Two Fields */}
  <div className="mb-0">
    <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900">
      Amount
    </label>
    <input
      type="number"
      id="amount"
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      placeholder="Enter amount"
    />
  </div>

  <div className="mb-0">
    <label htmlFor="payment-date" className="block mb-2 text-sm font-medium text-gray-900">
      Payment Date
    </label>
    <input
      type="date"
      id="payment-date"
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    />
  </div>

  {/* Third Line - Two Fields */}
  <div className="mb-5">
    <label htmlFor="premium-washes" className="block mb-2 text-sm font-medium text-gray-900">
      Premium Washes
    </label>
    <input
      type="number"
      id="premium-washes"
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      placeholder="Enter number"
    />
  </div>

  <div className="mb-5">
    <label htmlFor="quick-washes" className="block mb-2 text-sm font-medium text-gray-900">
      Quick Washes
    </label>
    <input
      type="number"
      id="quick-washes"
      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      placeholder="Enter number"
    />
  </div>
</div>


            <div className="mb-5">
              <label htmlFor="customer-name" className="block mb-2 text-sm font-medium text-gray-900">
                Customer Name
              </label>
              <input
                type="text"
                id="customer-name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter customer name"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="mobile-number" className="block mb-2 text-sm font-medium text-gray-900">
                Mobile Number
              </label>
              <input
                type="text"
                id="mobile-number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter mobile number"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900">
                Address
              </label>
              <input
                type="text"
                id="address"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter address"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="latitude" className="block mb-2 text-sm font-medium text-gray-900">
                Latitude & Longitude
              </label>
              <input
                type="text"
                id="latitude"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter latitude & longitude"
              />
            </div>
          </div>

          {/* Right Column */}
          <div className="container text-start">
            <div className="mb-5">
              <label htmlFor="vehicle-name" className="block mb-2 text-sm font-medium text-gray-900">
                Vehicle Name
              </label>
              <select
                id="next-wash-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Enter vehicle name</option>
                <option value="1">vehicle 1</option>
                <option value="2">vehicle 2</option>
              </select>
            </div>

            <div className="mb-5">
              <label htmlFor="vehicle-model" className="block mb-2 text-sm font-medium text-gray-900">
                Vehicle Model
              </label>
              <input
                type="text"
                id="vehicle-model"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter vehicle model"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="vehicle-color" className="block mb-2 text-sm font-medium text-gray-900">
                Vehicle Color
              </label>
              <select
                id="next-wash-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Enter vehicle color</option>
                <option value="1">color 1</option>
                <option value="2">color 2</option>
              </select>
            </div>

            <div className="mb-5">
              <label htmlFor="vehicle-no" className="block mb-2 text-sm font-medium text-gray-900">
                Vehicle No
              </label>
              <input
                type="text"
                id="vehicle-no"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter vehicle number"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="desired-date" className="block mb-2 text-sm font-medium text-gray-900">
                Desired Date
              </label>
              <input
                type="date"
                id="desired-date"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="desired-time" className="block mb-2 text-sm font-medium text-gray-900">
                Desired Time
              </label>
              <input
                type="time"
                id="desired-time"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </div>

            <div className="mb-5">
              <label htmlFor="next-wash-type" className="block mb-2 text-sm font-medium text-gray-900">
                Next Wash Type
              </label>
              <select
                id="next-wash-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Select wash type</option>
                <option value="1">Wash 1</option>
                <option value="2">Wash 2</option>
              </select>
            </div>

            <div className="mb-5">
              <label htmlFor="next-wash-date" className="block mb-2 text-sm font-medium text-gray-900">
                Next Wash Date
              </label>
              <input
                type="date"
                id="next-wash-date"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-center mt-6">
          <button
            type="submit"
            className="w-full md:w-auto flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-[#000] hover:bg-[#3385c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Submit
          </button>
        </div>
      </MainSection>
    </div>
  );
}

export default AddPackageUser;
