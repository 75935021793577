import React from "react";
import { Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import ReusableButton from "../../components/button/Button";
import Background from "../../assets/Background.png";

const Login = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/dashboard");
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Header Section */}
      <div className="w-full  p-4 bg-white rounded-t-lg mb-4 mt-[-40px] mx-5">
        <h1 className="text-3xl font-bold text-center">Clean Our Car</h1>
      </div>

      {/* Login Form Section */}
      <div className="w-full max-w-sm px-6 py-2 shadow-lg rounded-lg bg-white mt-[100px]">
        <h3 className="text-2xl font-bold text-center mb-6 leading-16 pb-1">
          Login To Admin Panel
        </h3>

        <Form
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
   <Form.Item
  label={<span style={{ color: '#000', fontWeight: 'bold', display: 'block', textAlign: 'left' }}>Email Address</span>}
  name="Email Address"
  rules={[{ required: true, message: "Please input your email!" }]}
  labelCol={{ span: 24 }} // Ensures the label is on a separate line
  wrapperCol={{ span: 24 }} // Ensures the input field is on a separate line
>
  <Input placeholder="Enter your email address" />
</Form.Item>




<Form.Item
  label={
    <span
      style={{
        color: '#000', // Set the desired color
        fontWeight: 'bold', // Make the label bold
        display: 'block', // Display the label on a separate line
        textAlign: 'left', // Align the label to the start
      }}
    >
      Password
    </span>
  }
  name="password"
  rules={[{ required: true, message: "Please input your password!" }]}
  labelCol={{ span: 24 }} // Ensures the label is on a separate line
  wrapperCol={{ span: 24 }} // Ensures the input field is on a separate line
>
  <Input.Password placeholder="Enter your password" />
</Form.Item>


          <Form.Item
            name="remember"
            valuePropName="checked"
            className="text-left"
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>

          <Form.Item className="text-center">
            <ReusableButton
              label="Log In"
              className="login-btn bg-[#000] text-white"
              onClick={handleClick}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
