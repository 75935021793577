import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Routes,
} from "react-router-dom";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import VendorManagement from "./pages/Vendor-Management/VendorManagement";
import AddVendor from "./pages/Vendor-Management/AddVendor";
import AddSubVendor from "./pages/Vendor-Management/AddSubVendor";
import EditVendor from "./pages/Vendor-Management/EditVendor";





import PackageManagement from "./pages/Package_Management/PackageManagement" ;
import Addpakage from "./pages/Package_Management/Addpackage";
import PackageUserManagement from "./pages/Package_users/PackageUserManagement";
import AddPackageUser from "./pages/Package_users/AddPackageUser";
import WashBoyManagement from "./pages/Wash_Boy_Management/WashBoyManagement";
import AddWashBoy from "./pages/Wash_Boy_Management/AddWashBoy";
import AllUserManagement from "./pages/All_users/AllUserManagement";



function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />

          {/* Vendor Management Routes */}
          <Route path="/Enquiry" element={<VendorManagement />} />
          <Route path="/Addenquiry" element={<AddVendor />} />
          <Route
            path="/vendor-management/edit-vendor"
            element={<EditVendor />}
          />
          <Route
            path="/vendor-management/add-subvendor"
            element={<AddSubVendor />}
          />

          {/* Task Management Routes */}
         
   
        
       


          <Route path="/PackageManagement" element={<PackageManagement />} />
          <Route path="/Package_Management/Addpackage" element={<Addpakage />} />
          <Route path="/PackageUserManagement" element={<PackageUserManagement />} />
          <Route path="/Package_users/AddPackageUser" element={<AddPackageUser />} />
          <Route path="/WashBoyManagement" element={<WashBoyManagement />} />
          <Route path="/Wash_Boy_Management/AddWashBoy" element={<AddWashBoy />} />
          <Route path="/AllUserManagement" element={<AllUserManagement />} />
          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
