import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect } from "react";
import $ from "jquery"; // Import jQuery
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net"; // Import DataTables
import MainSection from "../../components/mainLayout/Main";
import SearchBar from "../../components/searchbar/SearchBar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import DatatableSection from "../../components/datatableSection/DatatableSection";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai"; // Import the icons from react-icons

// Sample data to populate the table
const data = [];
for (let i = 1; i <= 10; i++) {
  data.push({
    purchaseDate: "02/02/23",
    expiryDate: "02/02/23",
    paymentDate: "02/02/23",
    amount: [500, 600, 800, 1200][i % 4],
    premiumWash: `0${i % 4 + 1}`,
    quickWash: `0${i % 4 + 1}`,
    name: `Customer Name`,
    mobileNo: "9123456789",
    vehicleName: ["Renault Duster", "Ford Figo", "Fiat Punto", "Tata Harrier", "Jeep Compass", "Honda City"][i % 6],
    vehicleNo: ["KA 41 Z 5493", "KA 55 AM 4395", "KA 01 MJ 1456"][i % 3],
  });
}

const PackageUserManagement = () => {
  const navigate = useNavigate();

  const handleAddpackageuser = () => {
    const addVendorPath = "/Package_users/AddPackageUser";
    navigate(addVendorPath);
  };

  useEffect(() => {
    // Initialize DataTable after the component has mounted
    $("#vendorTable").DataTable({
      paging: true,
      searching: true,
      ordering: true,
      pageLength: 10,
      lengthMenu: [10, 20, 30, 50],
      autoWidth: false, // Prevents fixed width setting by DataTables
    });

    // Cleanup function to destroy the DataTable when the component unmounts
    return () => {
      $("#vendorTable").DataTable().destroy();
    };
  }, []);

  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <div className="flex items-center justify-between mb-4">
          <SectionHeader title="Package Users" />
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            style={{ borderRadius: "5px" }}
            onClick={handleAddpackageuser}
          >
            Create New
          </button>
        </div>

        <SearchBar
          titleOne="Add Vendor"
          addVendorPath="/vendor-management/add-vendor"
          editVendorPath="/vendor-management/edit-vendor"
          showEditButton={true}
        />

        {/* Full-width table container */}
        <DatatableSection className="w-full overflow-x-auto">
        <div className="overflow-x-auto">
          <table id="vendorTable" className="display w-full">
            <thead>
              <tr>
                <th>Purchase Date</th>
                <th>Expiry Date</th>
                <th>Payment Date</th>
                <th>Amount</th>
                <th>Premium Wash</th>
                <th>Quick Wash</th>
                <th>Name</th>
                <th>Mobile No</th>
                <th>Vehicle Name</th>
                <th>Vehicle No</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.purchaseDate}</td>
                  <td>{item.expiryDate}</td>
                  <td>{item.paymentDate}</td>
                  <td>{item.amount}</td>
                  <td>{item.premiumWash}</td>
                  <td>{item.quickWash}</td>
                  <td>{item.name}</td>
                  <td>{item.mobileNo}</td>
                  <td>{item.vehicleName}</td>
                  <td>{item.vehicleNo}</td>
                  <td className="flex items-center space-x-2">
                    <button className="bg-black text-white px-1 py-1 rounded flex items-center">
                       <AiOutlineEye className="ml-0" />
                    </button>
                    <button className="bg-black text-white px-1 py-1 rounded flex items-center">
                       <AiOutlineEdit className="ml-0" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </DatatableSection>
      </MainSection>
    </>
  );
};

export default PackageUserManagement;
