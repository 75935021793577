import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect } from "react";
import $ from "jquery"; // Import jQuery
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net"; // Import DataTables
import MainSection from "../../components/mainLayout/Main";
import SearchBar from "../../components/searchbar/SearchBar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import DatatableSection from "../../components/datatableSection/DatatableSection";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai"; // Import the eye icon from react-icons

const data = [];
for (let i = 1; i <= 10; i++) {
  // Add sample data for demo purposes
  data.push({
    enquiryId: `COC${i}023`,
    date: "02/02/23",
    customerName: `Customer Name`,
    mobileNo: "9123456789",
    address: "Lorem ipsum dolor sit amet...",
    vehicleName: ["Renault Duster", "Ford Figo", "Fiat Punto", "Tata Harrier", "Jeep Compass", "Honda City"][i % 6],
    vehicleNo: ["KA 41 Z 5493", "KA 55 AM 4395", "KA 01 MJ 1456"][i % 3],
    customerType: ["New", "Existing"][i % 2],
    desiredTimeDate: "7:00 a.m., 02/02/24",
  });
}

const VendorManagement = () => {
  const navigate = useNavigate();

  const handleAddVendor = () => {
    const addVendorPath = "/Addenquiry";
    navigate(addVendorPath);
  };

  useEffect(() => {
    // Initialize DataTable after the component has mounted
    $("#vendorTable").DataTable({
      paging: true,
      searching: true,
      ordering: true,
      pageLength: 10,
      lengthMenu: [10, 20, 30, 50],
    });

    // Cleanup function to destroy the DataTable when the component unmounts
    return () => {
      $("#vendorTable").DataTable().destroy();
    };
  }, []);

  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <div className="flex items-center justify-between mb-4">
          <SectionHeader title="All Enquiries" />
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            style={{ borderRadius: "5px" }}
            onClick={handleAddVendor}
          >
            Create New Enquiry
          </button>
        </div>

        <SearchBar
          titleOne="Add Vendor"
          addVendorPath="/vendor-management/add-vendor"
          editVendorPath="/vendor-management/edit-vendor"
          showEditButton={true}
        />

        {/* Table structure matching the provided image */}
        <DatatableSection>
        <div className="overflow-x-auto">
          <table id="vendorTable" className="display">
            <thead>
              <tr>
                <th>Enquiry ID</th>
                <th>Date</th>
                <th>Customer Name</th>
                <th>Mobile No</th>
                <th>Address</th>
                <th>Vehicle Name</th>
                <th>Vehicle No</th>
                <th>Customer Type</th>
                <th>Desired Time & Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.enquiryId}</td>
                  <td>{item.date}</td>
                  <td>{item.customerName}</td>
                  <td>{item.mobileNo}</td>
                  <td>{item.address}</td>
                  <td>{item.vehicleName}</td>
                  <td>{item.vehicleNo}</td>
                  <td>
                    <select className="border border-gray-300 rounded px-2 py-1">
                      <option value="New" selected={item.customerType === "New"}>
                        New
                      </option>
                      <option value="Existing" selected={item.customerType === "Existing"}>
                        Existing
                      </option>
                    </select>
                  </td>
                  <td>{item.desiredTimeDate}</td>
                  <td>
                    <button className="bg-black text-white px-3 py-1 rounded flex items-center">
                      View <AiOutlineEye className="ml-2" /> {/* Eye icon with a margin on the left */}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </DatatableSection>
      </MainSection>
    </>
  );
};

export default VendorManagement;
