import Sidebar from "../../components/sidebar/Sidebar";
import React, { useEffect } from "react";
import $ from "jquery"; // Import jQuery
import "datatables.net-dt/css/dataTables.dataTables.min.css";
import "datatables.net"; // Import DataTables
import MainSection from "../../components/mainLayout/Main";
import SearchBar from "../../components/searchbar/SearchBar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import DatatableSection from "../../components/datatableSection/DatatableSection";
import { useNavigate } from "react-router-dom";
import { AiOutlineEdit } from "react-icons/ai"; // Import the edit icon from react-icons

// Sample data to populate the table
const data = [];
for (let i = 1; i <= 10; i++) {
  data.push({
    packageId: `COC${i}023`,
    packageName: "Package Name",
    packageDuration: ["01 Month", "02 Months", "03 Months"][i % 3],
    packagePricing: [500, 1000][i % 2],
    premiumWashes: `0${i % 2 + 1}`,
    quickWashes: `0${i % 2 + 1}`,
    status: i % 2 === 0, // Simulate toggle status
  });
}

const PackageManagement = () => {
  const navigate = useNavigate();

  const handleAddPackage = () => {
    const addVendorPath = "/Package_Management/Addpackage";
    navigate(addVendorPath);
  };

  useEffect(() => {
    // Initialize DataTable after the component has mounted
    $("#packageTable").DataTable({
      paging: true,
      searching: true,
      ordering: true,
      pageLength: 10,
      lengthMenu: [10, 20, 30, 50],
    });

    // Cleanup function to destroy the DataTable when the component unmounts
    return () => {
      $("#packageTable").DataTable().destroy();
    };
  }, []);

  return (
    <>
      <div>
        <Sidebar />
      </div>

      <MainSection>
        <div className="flex items-center justify-between mb-4">
          <SectionHeader title="Package Management" />
          <button
            className="bg-black text-white px-4 py-2 rounded-md"
            style={{ borderRadius: "5px" }}
            onClick={handleAddPackage}
          >
            Create Package
          </button>
        </div>

        <SearchBar
          titleOne="Add Vendor"
          addVendorPath="/vendor-management/add-vendor"
          editVendorPath="/vendor-management/edit-vendor"
          showEditButton={true}
        />

        {/* Table structure matching the provided image */}
        <DatatableSection>
        <div className="overflow-x-auto">
          <table id="packageTable" className="display">
            <thead>
              <tr>
                <th>Package ID</th>
                <th>Package Name</th>
                <th>Package Duration</th>
                <th>Package Pricing</th>
                <th>Premium Washes</th>
                <th>Quick Washes</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.packageId}</td>
                  <td>{item.packageName}</td>
                  <td>{item.packageDuration}</td>
                  <td>{item.packagePricing}</td>
                  <td>{item.premiumWashes}</td>
                  <td>{item.quickWashes}</td>
                  <td>
                    {/* Toggle Switch */}
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={item.status}
                        className="sr-only peer"
                        onChange={() => {}}
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 rounded-full peer dark:bg-gray-700 peer-checked:bg-blue-600"></div>
                      <span className="absolute inset-y-0 left-0 w-4 h-4 bg-white border border-gray-300 rounded-full transition-transform peer-checked:translate-x-5"></span>
                    </label>
                  </td>
                  <td>
                    <button className="bg-black text-white px-2 py-1 rounded flex items-center">
                      <AiOutlineEdit className="mr-1" /> {/* Edit icon */}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </DatatableSection>
      </MainSection>
    </>
  );
};

export default PackageManagement;
