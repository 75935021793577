import React from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import SectionHeader from "../../components/sectionHeader/SectionHeader";
import MainSection from "../../components/mainLayout/Main";

function Addpakage() {
  return (
    <div>
      <Sidebar />

      <MainSection>
        <SectionHeader title="Create New Package" />
        <hr/>

        {/* Container for two-column layout */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-5">
          {/* Left Column */}
          <div className="container text-start">
           

            <div className="mb-5">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Package Name
              </label>
              <input
                type="text"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter package name"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="mobile"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
              Package Duration
              </label>
              <select
                id="customer-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Select package duration</option>
                <option value="1">Select 1</option>
                <option value="2">Select 2</option>
                <option value="3">Select 3</option>
                <option value="4">Select 4</option>
              </select>
            </div>

            <div className="mb-5">
              <label
                htmlFor="address"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Package Pricing
              </label>
              <input
                type="text"
                id="address"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter package pricing"
                required
              />
            </div>

            <div className="mb-5">
              <label
                htmlFor="latitude"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
               No Of Quick Wash
              </label>
              <select
                id="customer-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Select number of washes</option>
                <option value="1">Select 1</option>
                <option value="2">Select 2</option>
                <option value="3">Select 3</option>
                <option value="4">Select 4</option>
              </select>
            </div>

            <div className="mb-5">
              <label
                htmlFor="customer-type"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
               No Of Premium Washes
              </label>
              <select
                id="customer-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Select number of washes</option>
                <option value="1">Select 1</option>
                <option value="2">Select 2</option>
                <option value="3">Select 3</option>
                <option value="4">Select 4</option>
              </select>
            </div>

            <div className="mb-5">
              <label
                htmlFor="reference-type"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Status
              </label>
              <select
                id="reference-type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option selected>Active / Inactive</option>
                <option value="1">Select 1</option>
                <option value="2">Select 2</option>
                <option value="3">Select 3</option>
                <option value="4">Select 4</option>
              </select>
            </div>
          </div>

       
        </div>

        <div className="flex justify-left mt-6">
        <button
            type="submit"
            className="flex justify-left py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-[#000] hover:bg-[#3385c0] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Create New Enquiry
          </button>
        </div>
      </MainSection>
    </div>
  );
}

export default Addpakage;
